import { TogglePasswordDirective } from './directives/password-toggle.directive';
import { DragDropDirective } from './directives/drag-drop.directive';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { FormsModule } from '@angular/forms';

import { MyLinkRendererComponent } from './components/ag-grid/my-link-renderer';
import { MasterActionButtonsComponent } from './components/ag-grid/action-buttons/action-buttons.component';
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './components/table/table.component';
import { StepperComponent } from './components/stepper/stepper.component';

// ag-grid
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ErrorHandlingComponent } from './components/error-handling/error-handling.component';
import { SuccessBlogComponent } from './components/success-blog/success-blog.component';



import { ReactiveFormsModule } from '@angular/forms';

import { DigitOnlyDirective } from './directives/digit-only.directive';
import { CapitalizePipe } from './pipes/dummy.pipe';
import { EmployeeSearchComponent } from './components/employee-search/employee-search.component';
import { ImportsComponent } from './modules/imports/imports.component';
import { ImportsFileComponent } from './components/imports-file/imports-file.component';
import { SelectStateComponent } from './components/select-state/select-state.component';
import { ProfileUploadComponent } from './components/profile-upload/profile-upload.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';






@NgModule({
  declarations: [TableComponent, StepperComponent, DigitOnlyDirective, AgGridComponent,
    MasterActionButtonsComponent, MyLinkRendererComponent, ErrorHandlingComponent,
    SuccessBlogComponent, LogoUploadComponent, UploadFileComponent,ProfileUploadComponent,
    DragDropDirective, CapitalizePipe, EmployeeSearchComponent, ImportsFileComponent, TogglePasswordDirective, SelectStateComponent, ProfileUploadComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([null]),
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [DigitOnlyDirective, DragDropDirective, TableComponent, LogoUploadComponent,
    StepperComponent, AgGridComponent, MasterActionButtonsComponent,ProfileUploadComponent,
    ErrorHandlingComponent, SuccessBlogComponent, LogoUploadComponent,
    UploadFileComponent, EmployeeSearchComponent, ImportsFileComponent, TogglePasswordDirective, SelectStateComponent],
  entryComponents: [MasterActionButtonsComponent, MyLinkRendererComponent],
  providers: [DigitOnlyDirective, DragDropDirective, TogglePasswordDirective]
})
export class SharedModule {

}
