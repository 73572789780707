import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { LoadingService } from 'src/app/services/loading.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientService } from 'src/app/services/httpClient.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  feedbackForm: FormGroup;


  constructor(
    private _formBuilder: FormBuilder,
    private _loader: LoadingService,
    private _httpclientservice: HttpClientService,
    private _toaster: ToasterService

  ) { }



  ngOnInit() {
    this.feedbackForm = this._formBuilder.group({
      UserFeedback: [''],
      rating: ['']

    });

  }
  onsubmitFeeedback() {
    // loader start
    this._loader.loaderStart();
    console.log('user agent', window.navigator.userAgent);
    var itfeedback = this.feedbackForm.value;
    let obj = {
      Feedback: this.feedbackForm.value.UserFeedback,
      Application: 'service portal',
      Rating: parseInt(this.feedbackForm.value.rating),
      LoginName: JSON.parse(localStorage.getItem('logged_in_user')).username,
      // CreatedAt: '1582149575',
      UserName: JSON.parse(localStorage.getItem('logged_in_user')).username,
      // this._loggedInUserService.getLoggedInUser(),
      UserRole: '',
      UserAgent: navigator.userAgent
    };
    this._httpclientservice
      .post('feedback', obj, 'apiDevBaseUrladmin')
      .subscribe(
        newITslab => {
          this._loader.loaderEnd();
          // loader End
          this._toaster.success('Feedback Added Successfully');
          this.feedbackForm.reset();
        },
        err => {
          // this._loader.loaderEnd();
          // this._toaster.error(err);
          this._toaster.error('error');
        }
      );
  }
}
