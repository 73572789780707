import { LoggedInUserService } from './../../../../services/loggedInUser.service';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';


declare function enableTreeMenu(): any; // reading external js method

var arrayToTree = require('array-to-tree'); // flat menu to tree menu


@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.css']
})
export class LeftSideMenuComponent implements OnInit {

  menuElements;
  getCodesAndValues(): Observable<any[]> {
    return forkJoin(
      this._httpClientService.get('countriesaskeyvalue', 'apiDevBaseUrlsys'),
      this._httpClientService.get('states', 'apiDevBaseUrlsys'),
      this._httpClientService.get('cities', 'apiDevBaseUrlsys'),
      this._httpClientService.get('fieldvaluespair', 'apiDevBaseUrladmin'),
      this._httpClientService.get('fieldcodespair', 'apiDevBaseUrladmin'),
      this._httpClientService.get('orgbranchespair', 'apiDevBaseUrlorg'),
      // this._httpClientService.get('payroll/components/keyvalue/OTHER EARNINGS - REIMB', 'apiDevBaseUrlorg'),

    );
  }

  getEnums() {
    return this._httpClientService.get('enums', 'apiDevBaseUrlsys');
  }
  getEmpIdNameNumbers() {
    return this._httpClientService.get('employees/empid-number-name-pair', 'apiDevBaseUrlemp');
  }


  constructor(
    private _httpClientService: HttpClientService
  ) { }

  ngOnInit() {

    this.getCodesAndValues().subscribe(res => {
      console.log('fork join responxe', res);
      const codesValues = ['countries', 'states', 'cities', 'fieldvaluespair', 'fieldcodespair','orgbranchespair'];
      let mergedRes = {};
      let filteredRes = res.map((res: any, i) => {
        console.log('index fork ', i);
        return mergedRes[codesValues[i]] = res.d;
      })
      // let mergedRes = filteredRes[0].concat(filteredRes[1], filteredRes[2], filteredRes[3], filteredRes[4], filteredRes[5]);
      console.log('merged', mergedRes);
      localStorage.setItem('codesAndValues', JSON.stringify(mergedRes));
    }, err => {
      console.log('fork join error', err);
    });

    // get all enums
    this.getEnums().subscribe((res: any) => {
      console.log('enums response', res.d);
      localStorage.setItem('enums', JSON.stringify(res.d));
    });

    const entitie = JSON.parse(localStorage.getItem('entitie'));
    // switch-session calling
    this._httpClientService.post('sessions/' + entitie.Type + '/' + entitie.Key, '', 'apiDevBaseUrlsys').subscribe(
      // success callback
      (switchSessionRes: any) => {
        console.log('switchSessionRes', switchSessionRes);
        localStorage.setItem('refreshHash', switchSessionRes.d.SecretHash);
        localStorage.setItem('MenuElements', JSON.stringify(switchSessionRes.d.MenuElements));
        localStorage.setItem('Permissions', JSON.stringify(switchSessionRes.d.Permissions));
        localStorage.setItem('Role', JSON.stringify(switchSessionRes.d.Role));

        // checking OT Applicability
        this._httpClientService.get('app/configuration?paramgroup=OT&paramkey=is.overtime.applicable',
          'apiDevBaseUrlorg').subscribe((res: any) => {
            console.log('checking OT Applicability res', res.d);
            if (res.d) {
              if (res.d[0].Value == 'false') {
                // if aplicability is false remving from array
                let filteredMenuItems = [];
                for (let item of switchSessionRes.d.MenuElements) {
                  if (item.key != 'payroll-overtimeregister') {
                    filteredMenuItems.push(item);
                  }
                }
                console.log('filteredMenuItems after congigarations', filteredMenuItems);
                this.flatToHierarchy(filteredMenuItems);
              } else {
                // if aplicability is true
                this.flatToHierarchy(switchSessionRes.d.MenuElements);
              }
            } else {
              // if it is a first time
              this.flatToHierarchy(switchSessionRes.d.MenuElements);
            }

          }, err => {
            this.flatToHierarchy(switchSessionRes.d.MenuElements);
          });

        // checking Loan Applicability
        // checking OT Applicability
        this._httpClientService.get('app/configuration?paramgroup=Loans&paramkey=are.loans.applicable',
          'apiDevBaseUrlorg').subscribe((res: any) => {
            console.log('checking OT Applicability res', res.d);
            if (res.d) {
              if (res.d[0].Value == 'false') {
                // if aplicability is false remving from array
                let filteredMenuItems = [];
                for (let item of switchSessionRes.d.MenuElements) {
                  if (item.key != 'payroll-loan') {
                    filteredMenuItems.push(item);
                  }
                }
                console.log('filteredMenuItems after congigarations', filteredMenuItems);
                this.flatToHierarchy(filteredMenuItems);
              } else {
                // if aplicability is true
                this.flatToHierarchy(switchSessionRes.d.MenuElements);
              }
            } else {
              // if it is a first time
              this.flatToHierarchy(switchSessionRes.d.MenuElements);
            }

          }, err => {
            this.flatToHierarchy(switchSessionRes.d.MenuElements);
          });

      },
      // error callback
      switchSessionError => {
        console.log('switchSessionError', switchSessionError);
      }
    );

    this.getEmpIdNameNumbers().subscribe((res: any) => {
      console.log('getEmpIdNameNumbers response', res.d);
      localStorage.setItem('emp_id_name_numbers', JSON.stringify(res.d));
    });

  }

  flatToHierarchy(flat) {

    // sorting before sending to arrayToTree
    flat.sort((a, b) => { return a.order - b.order; });
    console.log('Ordered Menu', flat);

    this.menuElements = arrayToTree(flat, {
      parentProperty: 'parent',
      customID: 'key'
    });
    console.log('MenuElements', this.menuElements);

    // calling external js method to prepare child menu
    enableTreeMenu();
  }
}
