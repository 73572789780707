import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ItDeclarationService {
    selectedRowDataEmp;
    employeeID;
    selectedfinancialyear;
    selectedempName;
    selectedempnum;
    selectedHouserentDetails;
    previousempDetails;
    itDeclarationDetailsChanged = new Subject();
    TaxRegime;
    itTaxregimeChanged = new Subject();
    selectedEmpData
    isEmployeeIdExists = false;

    setEmployeeData(val) {
        this.selectedEmpData = val;
    }

    getEmployeeData() {
        return this.selectedEmpData;
    }


    setEmp(val) {
        this.selectedRowDataEmp = val;
    }

    getEmp() {
        return this.selectedRowDataEmp;
    }

    // setter
    setEmployeeID(id) {
        this.employeeID = id;
    }

    // getter
    getEmployeeID() {
        return this.employeeID;
    }

    // added by Bhanu to Get Financial Year
    setfinancialyear(val){
        this.selectedfinancialyear = val;

    }

    getfinancialyear(){
        return this.selectedfinancialyear;
    }

    setEmpNum(val){
        this.selectedempnum = val;
    }

    getEmpNum(){
        return this.selectedempnum;
    }

    setEmpName(val){
        this.selectedempName =val;
    }

    getEmpName(){
        return this.selectedempName;   
    }
    setHouseRentDetails(val){
        this.selectedHouserentDetails= val;

    }

    getHouseRentDetails(){
        return this.selectedHouserentDetails;
    }

    setPreviousEmployeeDetails(val){
        this.previousempDetails = val;
    }

    getPreviuosEmployeeDetails(){
        return this.previousempDetails;
    }

    setTaxRegime(val){
      this.TaxRegime =val;
    }

    getTaxRegime(){
     return this.TaxRegime
    }

   
}
