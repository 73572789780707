import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  clickedMasterDataItem;
  getSelectedStateCode;
  stateParameterChanged = new Subject();
  // serter
  setMasterDataItem(selectedItem) {
    this.clickedMasterDataItem = selectedItem;
  }

  // getter
  getMasterDataItem() {
    return this.clickedMasterDataItem;
  }


  // setter
  setSelectedState(ItemCode) {
    this.getSelectedStateCode = ItemCode;
  }

  // getter
  getSelectedState() {
    return this.getSelectedStateCode;
  }

  constructor() { }
}
