import { Component, OnInit, Input } from '@angular/core';
import Stepper from 'bs-stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {

  private stepper: Stepper;

  @Input()
  stepperTitles;

  @Input()
  stepperDataComponents;

  constructor() { }

  next() {
    this.stepper.next();
  }

  onSubmit() {
    return false;
  }

  ngOnInit() {

    // calling before html is rendering so we are using set timeout to call after one sec
    setTimeout(() => {
      this.stepper = new Stepper(document.querySelector('#stepper1'), {
        linear: false,
        animation: true
      })
    }, 1000);


  }

}
