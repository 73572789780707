import { EmployeeMasterService } from 'src/app/services/employee-master-service';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { ToasterService } from './../../../services/toaster.service';
import { LoadingService } from './../../../services/loading.service';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import Utils from 'src/app/shared/utility.functions';
import { TranslateCodeToValue } from '../../../shared/pipes/translateCodeToValue.pipe';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.css'],
  providers:[TranslateCodeToValue]
})
export class EmployeeSearchComponent implements OnInit {
  searchParms;
  searchResults;
  employeeSelected;
  Utils;

  @Input() displayCard: boolean;
  @Input() editMode: boolean;
  @Output() selectedEmployee = new EventEmitter();
  myEventSubscription: any;
  empdesignation

  getFilteredEmployees() {
    // if (this.searchParms.length > 2) {
      console.log('employees srart with', this.searchParms.length);
      try {
        this._loader.loaderStart();
        this._httpClientService
          .get('search?term=' + this.searchParms, 'apiDevBaseUrlemp')
          .subscribe(
            (searchRes: any) => {
              this._loader.loaderEnd();
              this.searchResults = searchRes.d;
              console.log('searchResults', this.searchResults);
            },
            (err: any) => {
              this._loader.loaderEnd();
              this._toasterService.error(err.error.m);
            }
          );

      } catch (err) {
        this._loader.loaderEnd();
        this._toasterService.error(err);
      }
    // }
  }

  selectEmployee(employee) {
    console.log('selected employee', employee);
    this.employeeSelected = employee;
    this.searchResults = null;

    this._httpClientService.get('employees/' + employee.ID, 'apiDevBaseUrlemp').subscribe((res: any) => {
      console.log('res.d', res.d);
      this.employeeSelected = res.d;
      this._employeeMasterService.setSelectedEmpFromSearch(res.d);
      this.selectedEmployee.emit(res.d);
      this.searchResults = null;
      this.searchParms = null;
      console.log("designation",this.empdesignation);
    }, err => {
      this.searchResults = null;
      this.searchParms = null;
    });

  }

  constructor(private _loader: LoadingService, private _httpClientService: HttpClientService, private _toasterService: ToasterService, private _employeeMasterService: EmployeeMasterService,private _translateCodeToValue: TranslateCodeToValue) { }

  ngOnInit() {
    
    this.employeeSelected = this._employeeMasterService.getSelectedEmpFromSearch();
    console.log('search search',this.employeeSelected);

      // listening the subject varaible

   this._employeeMasterService.employeeChanged.subscribe((value)=>{
    console.log('***********inside subscribe value',value);
    this._employeeMasterService.setSelectedEmpFromSearch(value);
    this.employeeSelected = this._employeeMasterService.getSelectedEmpFromSearch();
});

// Added this for Clearing the Serach Employee


//this.myEventSubscription = this.someEvent.subscribe(() => {
  // DO SOMETHING
//})

//this.myEventSubscription.unsubscribe()

this.myEventSubscription = this._employeeMasterService.employeeSearchcardClear.subscribe((value)=>{
  this.searchParms = null;
  this.searchResults = false;
})

  }

  ngOnDestroy() {
    ///this._employeeMasterService.employeeSearchcardClear.unsubscribe();
    this.myEventSubscription.unsubscribe();
  }

}
