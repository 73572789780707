import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-profile-upload',
  templateUrl: './profile-upload.component.html',
  styleUrls: ['./profile-upload.component.css']
})
export class ProfileUploadComponent implements OnInit {

  @Output() uploadContent = new EventEmitter<any>();
  uploadData;
  uploadOverallData;
  public imagePath;
  imgURL: any;
  message: string;
  fileSize: string;
  fileToUpload;
  formData: FormData;
  files: any;
  showfile: any;

  rightTickMark: boolean = false;
  cancelTickMark: boolean = false;
  chooseFile: boolean = true;


  constructor(
    private _toasterService: ToasterService,
    private _httpClientService: HttpClientService,
    private _loader: LoadingService
  ) { }

  ngOnInit() {
  }

  // when user choose file and select the file, this function will execute
  preview(files) {
    console.log("preview", files)
    console.log(files[0].size)
    console.log(files[0].name);
    var img = files[0].name;
    var t = img.split('.');
    var ref_img = t[1];
    console.log(ref_img);
    this.showfile = ref_img;
    console.log(this.showfile)

    if (ref_img == 'png' || ref_img == 'jpg' || ref_img == 'jpeg' || ref_img == 'PNG' || ref_img == 'JPG' || ref_img == 'JPEG') {
      console.log('selected file is png');
      if (files[0].size > 512000 ) {
        this.fileSize = 'File size is exceed';
        this.message = '';
        this.rightTickMark = true;
        console.log('should not be more than 5kb')
        return;
      } else {
        this.chooseFile = false;
        this.rightTickMark = false;
        this.message = '';
        this.fileSize = '';
        this.fileToUpload = files.item(0);
        this.formData = new FormData();
        this.formData.append('file', this.fileToUpload, this.fileToUpload.name);

        var reader = new FileReader();
        // this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = _event => {
          this.imgURL = reader.result;
        };
      }
    }
    else {
      console.log('another format');
      this.message = 'Only jpg, jpeg, png formats are allowed';
      this.fileSize = '';
      this.fileToUpload = '';
      this.imgURL = '';
      this.rightTickMark = true;
      // this.formData = new FormData();
      return;
    }
  }

  // wehen user upload the file, this function will execute
  uploadFile() {
    this.rightTickMark = true;
    this._loader.loaderStart();
    this._httpClientService
      .post('uploadlogo', this.formData, 'apiDevBaseUrladmin')
      .subscribe(
        //success call back
        (data: any) => {
          this.uploadData = data.d;
          // this.uploadOverallData = this.uploadData.d;
           // this.uploadOverallData = this.uploadData.d;
           let fileInfo = {
            fileName: this.fileToUpload.name,
            uploadData: this.uploadData
          }
          
          this.uploadContent.emit(fileInfo);
          this._loader.loaderEnd();
          this._toasterService.success('Image uploaded Successfully');
          console.log('get upload', this.uploadData);
        }
      ),
      //error call back
      (err: {}) => {
        console.log('error', err);
        this._loader.loaderEnd();
        this._toasterService.error(
          'Something went wrong in Logo uploading'
        );
      };
  }

  clearImage() {
    this.chooseFile = true;
    this.showfile = '';
    this.imgURL = '';
  }

}

