import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TranslateEmpIDToNumberAndName'
})

export class TranslateEmpIDToNumberAndName implements PipeTransform {
  transform(type: string, value: string): string {

    console.log(' TranslateEmpIDToNumberAndName to value  pipe called...../////');
    const KeyValues = JSON.parse(localStorage.getItem('emp_id_name_numbers'));
    // console.log('TranslateEmpIDToNumberAndName.type.value', type, value, KeyValues[type]);
    if (KeyValues[type]) {
      return KeyValues[type][value];
    } else {
      return value;
    }

  }
}
