export const environment = {
  production: false,
  // awsCognitoUrl:
  //   'https://vpd-dev.auth.ap-south-1.amazoncognito.com/login?response_type=code&client_id=',
  // cognitoClientId: '2eqbepo193e0v7348q93lle3fs',
  // cognitoClientSecret: 'tajrqco5uqotve7udkmccda6ei8jd0mn7s58jmlems1nn8irst2',
  // cognitoAuthUrl:
  //   'https://vpd-dev.auth.ap-south-1.amazoncognito.com/oauth2/token',
  // cognitoLogoutUrl: 'https://vpd-dev.auth.ap-south-1.amazoncognito.com/logout',
  // cognitoRefeshTokenUrl: 'https://cognito-idp.ap-south-1.amazonaws.com/',

  // Dev Environment
  //old keys1
  // awsCognitoUrl:
  //   "https://vpd-test.auth.ap-south-1.amazoncognito.com/login?response_type=code&client_id=",
  // cognitoClientId: "6pbh7r9pc8ads48ldjv158024r",
  // cognitoClientSecret: "120fvte3f8kr7tbk9mlfrkf74757861dcj3pv8qm5mb1k3p90748",
  // cognitoAuthUrl:
  //   "https://vpd-test.auth.ap-south-1.amazoncognito.com/oauth2/token",
  // cognitoLogoutUrl: "https://vpd-test.auth.ap-south-1.amazoncognito.com/logout",
  // cognitoRefeshTokenUrl: "https://cognito-idp.ap-south-1.amazonaws.com/",
  //updated keys
  awsCognitoUrl:
    "https://vpd-uat.auth.ap-south-1.amazoncognito.com/login?response_type=code&client_id=",
  cognitoClientId: "66usg7hp2iui22h9r1obs5svdk",
  cognitoClientSecret: "13irr163l6scgo3e5enbf11tp6cgauu6sdjkpocvodla0kos6ujc",
  cognitoAuthUrl:
    "https://vpd-uat.auth.ap-south-1.amazoncognito.com/oauth2/token",
  cognitoLogoutUrl: "https://vpd-uat.auth.ap-south-1.amazoncognito.com/logout",
  cognitoRefeshTokenUrl: "https://cognito-idp.ap-south-1.amazonaws.com/",

  // // // AWS FULL URLS

  apiDevBaseUrl: "https://service-stage.cloudtaru.com/api",
  apiDevBaseUrlsys: "https://service-stage.cloudtaru.com/api/sys",
  apiDevBaseUrladmin: "https://service-stage.cloudtaru.com/api/admin",
  apiDevBaseUrlemp: "https://service-stage.cloudtaru.com/api/emp",
  apiDevBaseUrlorg: "https://service-stage.cloudtaru.com/api/org",
  apiDevBaseUrlmisc: "https://service-stage.cloudtaru.com/api/misc",
  apiDevBaseUrlImpex: "https://service-stage.cloudtaru.com/api/impex",
  apiDevSearchUrl: "https://service-stage.cloudtaru.com/api/emp",
  apiDevBaseUrlJob: "https://service-stage.cloudtaru.com/api/job",
  apiDevBaseUrlMsgCenter: "https://service-stage.cloudtaru.com/api/messages",
};
