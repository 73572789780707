// The file contents constant values like messages.

export const MessageConstants = {
  EMPLOYEE_BASICINFO_SUCCESSMSG: { msg: "Saved Successfully" },

  EMPLOYEE_BASICINFO_ADDMSG: { msg: "Added Successfully" },

  EMPLOYEE_UPDATE_SUCCESSMSG: { msg: "Updated Successfully" },

  EMPLOYEE_DELETE_SUCCESSMSG: { msg: "Deleted Successfully" },

  EMPLOYEE_UPLOAD_SUCCESSMSG: { msg: "Uploaded Successfully" },

  PAYROLL_SUBMIT_MSG: { msg: "Payroll Submitted Successfully" },

  PAYROLL_RESET_MSG: { msg: "Payroll Reset Successfully" },

  PAYROLL_APPROVE_MSG: { msg: "Payroll Approved Successfully" },

  PAYROLL_FINALIZE_MSG: { msg: "Payroll Finalized Successfully" },

  PAYROLL_LOCK_MSG: { msg: "Payroll Locked Successfully" },

  PAYROLL_MARK_CHECKLIST_MSG: {
    msg: "Please verify checklist items and mark them as Verified.Before Start Payroll Processing.",
  },

  PAYROLL_PAYSHEDULE_CHECK: {
    msg: "Cut off date is mandatory to process payroll.",
  },
  PAYROLL_EMPLOYEE_RECORD_NOT_FOUND: { msg: "Employee records not found." },

  UNAUTHORIZED_ACCESS: {
    msg: "You are not Authorized to perform this Action. Please contact Administrator",
  },

  // attendance toaster messages /component Name: attendence-leave.component.ts
  EMPLOYEE_ATTENDANCE_SUCCESSMSG: { msg: "Attendance Added Successfully" },

  EMPLOYEE_ATTENDANCE_UPDATEMSG: { msg: "Updated  Attendance Successfully " },

  EMPLOYEE_ATTENDANCE_DELETEMSG: { msg: "Deleted  Attendance Successfully " },

  // leave toaster Messages /component Name: attendence-leave.component.ts
  EMPLOYEE_LEAVE_SUCCESSMSG: { msg: "leave Added Successfully" },

  EMPLOYEE_LEAVE_UPDATEMSG: { msg: "Updated  Leave Successfully " },

  EMPLOYEE_LEAVE_DELETEMSG: { msg: "Deleted  Leave Successfully " },

  // leave toaster Messages /component Name: attendence-leave.component.ts
  EMPLOYEE_Arrears_SUCCESSMSG: { msg: "Arrear Added Successfully" },

  EMPLOYEE_Arrears_UPDATEMSG: { msg: "Updated  Arrear Successfully " },

  EMPLOYEE_Arrears_DELETEMSG: { msg: "Deleted  Arrear Successfully " },
  // documents

  EMPLOYEE_ADD_DOCUMENT_MSG: { msg: "Document added Successfully" },
  EMPLOYEE_DELETE_DOCUMENT_MSG: { msg: "Document deleted Successfully" },
  DOWNLOAD_DOCUMENT_MSG: { msg: "Document downloaded Successfully" },

  EMPLOYEE_PAYSLIPDISPATCH_MSG: {
    msg: "Employee PaySlip Dispatched Successfully",
  },

  // IT Declarations
  HOUSE_RENT_ADD_SUCCESSMSG: { msg: "House Rent Added Successfully" },
  HOUSE_RENT_UPDATE_SUCCESSMSG: { msg: "House Rent Updated Successfully" },
  HOUSE_RENT_DELETE_SUCCESSMSG: { msg: "House Rent Deleted Successfully" },
  PREVIOUSEMP_ADD_SUCCESSMSG: { msg: "Previous Employee Added Successfully" },
  PREVIOUSEMP_UPDATE_SUCCESSMSG: {
    msg: "Previous Employee Updated Successfully",
  },
  PREVIOUSEMP_DELETE_SUCCESSMSG: {
    msg: "Previous Employee Deleted Successfully",
  },
  COMPUTE_TAX_SUCCESSMESSAGE: { msg: "Compute Tax Saved Successfully" },
  IT_DECLARATION_DRAFT_MSG: { msg: "IT Declaration Saved Sucessfully" },
  IT_DECLARATION_SUBMIT_MSG: { msg: "IT Declaration Submitted Sucessfully" },
  IT_DECLARATION_APPROVE_MSG: { msg: "IT Declaration Approved Sucessfully" },
  IT_DECLARATION_REJECT_MSG: { msg: "IT Declaration Rejected Sucessfully" },
  IT_UPDATE_SUCCESS_MSG: {msg: "Income Tax Updated Successfully"},

  // Loans
  LOAN_ERROR_MESSAGE: {
    msg: "is  not eligible for Loan Please Contact Administrator",
  },

  // Form 16
  FORM_16_SUCCESSMSG: { msg: "Details Saved Successfully" },

  // salary Components

  // Common Toster Messages
  SUCCESS: { msg: "Saved Successfully" },
  SUCCESS_COMPLETED: { msg: "Successfully Completed" },
  UPDATE: { msg: "Updated Successfully" },
  DELETE: { msg: "Deleted Successfully" },
  ACTIVATE: { msg: "Activated Successfully" },
  DEACTIVATE: { msg: "Deactivated Successfully" },
  CATCH_MSG: { msg: "Something went Wrong. Please Try Again..!" },

  // full &final settlement
  Full_and_Finalsettlement_COMPONENTS_SAVE_SUCCESSMSG: {
    msg: "Full & Final Settlement Saved Successfully",
  },
  Full_and_Finalsettlement_COMPONENTS_UPDATE_SUCCESSMSG: {
    msg: "Full & Final Settlement Updated Successfully",
  },
  Full_and_Finalsettlement_COMPONENTS_SUBMIT_SUCCESSMSG: {
    msg: "Full & Final Settlement Submitted Successfully",
  },
  Full_and_Finalsettlement_COMPONENTS_APPROVED_SUCCESSMSG: {
    msg: "Full & Final Settlement Approved Successfully",
  },
  Full_and_Finalsettlement_COMPONENTS_REJECTED_SUCCESSMSG: {
    msg: "Full & Final Settlement Rejected Successfully",
  },
  Full_and_Finalsettlement_COMPONENTS_Notice_Period_Required: {
    msg: "Notice Period is Mandatory",
  },
  Full_and_Finalsettlement_COMPONENTS_Days_Served_Required: {
    msg: "No of Days Served is Mandatory",
  },
  Full_and_Finalsettlement_COMPONENTS_Payroll_Month_Required: {
    msg: "Payroll Month is Mandatory",
  },
  Full_and_Finalsettlement_COMPONENTS_Excess_Notice_Required: {
    msg: "Short in Notice is Mandatory",
  },
  Full_and_Finalsettlement_COMPONENTS_Prorated_excess_in_Notice_Required: {
    msg: "Prorated excess in Notice is Mandatory",
  },
  Full_and_Finalsettlement_COMPONENTS_Leave_Type_Required: {
    msg: "Leave Type is Mandatory",
  },
  Full_and_Finalsettlement_COMPONENTS_Encash_Required: {
    msg: "Encash Days is Mandatory",
  },
  Full_and_Finalsettlement_COMPONENTS_Encash_Greater_Required: {
    msg: "Encash Days can't be greater than Balance.",
  },

  SALARY_COMPONENTS_SAVE_SUCCESSMSG: {
    msg: "Salary Component Added Successfully",
  },

  // payschedule required fields
  cutoffday: { msg: "Monthly CutOff Date is Mandatory" },

  // Special Attributes
  SUBMIT_MSG: { msg: "Submitted Successfully" },
  SAVE_MSG: { msg: "Saved Successfully" },
  UPDATE_MSG: { msg: "Updated Successfully" },
  SAVED_AS_DRAFT: { msg: "Saved as Draft" },
  APPROVED_MSG: { msg: "Approved Successfully" },
  REJECTED_MSG: { msg: "Rejected Successfully" },

  // workflow
  ADD_WORKFLOW_MSG: { msg: "WorkFlow Added Successfully" },
  UPDATE_WORKFLOW_MSG: { msg: "Workflow Updated Successfully" },
  DELETE_WORKFLOW_MSG: { msg: "Deleted Successfully" },
  ASSIGN_ACTOR_MSG: { msg: "Actor assigned Successfully" },

  // Statutory
  STATUTORY_SUCCESS: { msg: "Statutory Added Successfully" },
  STATUTORY_WHEN_EMPTY: { msg: "Select any statutory component to save" },
  // employee salary & salary revison
  EMP_CTC_CALC_SUCCESS: { msg: "Employee CTC Calculated Succesfully" },
  EMP_CTC_UPDATE_SUCCESS: { msg: "Employee CTC Updated Succesfully." },
  EMP_CTC_COMPUTE_SUCCESS: { msg: "Employee CTC Computed Succesfully." },
  // Release IT Declaration
  IT_LOCKED_MSG: { msg: "Locked Successfully" },
  IT_RELEASE_MSG: { msg: "Released Successfully" },
  IT_SELECT_ATLEAST_ONE_EMPLOYEE: { msg: "Please Select Atleast One Employee" },

  // LOCATIONWISE SAL COMPONENT
  FORMULA_SUCCESS: { msg: "Formula Added Succesfully" },
  LOC_WISE_SALARY_SUCCESS: { msg: "Salary components added successfully" },
  LOC_WISE_SALARY_DELETE: { msg: "Deleted successfully" },

  // loan settings
  LOAN_TYPE_LIST_SUCCESS: { msg: "Loan Type List Saved  Successfully" },
  // payment in employee
  PAYMENT_INFO_SUCCESS: { msg: "Payment Information  Saved Successfully" },
  // separation in employee
  SEPERATION_SUCCESS: { msg: "Employee Separation Added Successfully" },
  SEPERATION_UPDATE: { msg: "Employee Separation Updated Successfully" },
  // checklist
  CHECK_LIST_SAVE_MSG: { msg: "Checklist Saved  Successfully" },

  // users
  ORG_ASSIGN_SUCCESS_MSG: { msg: "Organisation Assigned Successfully" },
  USER_UPDATE_SUCCESS_MSG: { msg: "User updated successfully" },

  // features
  ADD_FEATURE: { msg: "Features added successfully" },
  ATLEAST_ONE_FEATURE_MSG: {
    msg: "Please select atleast one feature for Role",
  },

  // roles
  DELETE_ROLE_MSG: { msg: "Role Deleted successfully." },
};

// validaton content place here
export const ValidationMessageConstants = {
  // Common Validation
  STATE: { msg: "State is Mandatory" },
  CITY: { msg: "City is Mandatory" },
  COUNTRY: { msg: "Country is Mandatory" },
  MOBILE: { msg: "Contact Number is Mandatory" },
  MOBILEFORMAT: {
    msg: "Invalid Contact Number Format, Contact number Starts with 6,7,8,9",
  },
  CONTACTNUMBER: { msg: "Contact Number is Mandatory" },
  CONTACTNUMBERFORMAT: {
    msg: "Invalid Contact Number Format, Contact number Starts with 6,7,8,9",
  },
  INVALID_CONTACT_NUMBER_FORMAT: {
    msg: "Invalid Contact Number Format, Contact number Starts with 6,7,8,9",
  },
  EMAIL: { msg: "Email Id is Mandatory" },
  PAN: { msg: "PAN is Mandatory" },
  TAN: { msg: "TAN is Mandatory" },
  EMAIL_FORMAT: { msg: "Invalid Email Id" },
  FROM_DATE: { msg: "From date Mandatory" },
  TO_DATE: { msg: "To date Mandatory" },
  DATE_MANDATORY: { msg: "Date is Mandatory" },
  GENDER: { msg: " Gender is Mandatory" },
  DOB: { msg: "DOB is Mandatory" },
  ADDRESS: { msg: "Address  is Mandatory" },
  ONLYALPHABETSANDSPACE_PATTERN: { msg: "Only Allows Alphabets and Space" },
  ONLYALPHANUMERICANDSPACE_PATTERN: {
    msg: "Only Allows AlphaNumeric and Space",
  },
  ALPHANUMERICWITHHYPEN: {
    msg: "  Only Allows AlphaNumeric with space and hypen",
  },
  ALPHANUMERICWITHSPACEANDBRACKETS: {
    msg: "Alphanumeric with space and bracket",
  },
  PAN_FORMAT: {
    msg: "Invalid PAN (4th character [P — Individual or Person ] [C — Company] Ex: AAAPZ1234C)",
  },
  // tslint:disable-next-line:max-line-length
  TAN_FORMAT: {
    msg: "Invalid TAN ( [First four characters are letters] [Next five are numerals] [Last character is a letter] Ex: RAJA99999B)",
  },
  AADHARFORMAT: { msg: "Aadhar Number must have 12 digits" },
  ADDRESSFORMAT: { msg: " Only Allows Alphanumeric with (@ - # /, .)" },
  PINCODEFORMAT: { msg: "PostalCode must have Minimum 6 digits." },
  NUMERICS: { msg: "Only Allows Numerics" },
  PERCENTAGE: { msg: "Only Allows Numbers 0.000 to 100" },
  NOZERO: { msg: "Value should be greater than 0" },
  YEAR: { msg: " Year is Mandatory" },
  MONTH: { msg: " Month is Mandatory" },

  // payschedule validations
  BRANCH_MAN: { msg: "Branch is Mandatory" },
  PAYROLL_FREQUENCY: { msg: "Payroll Frequency is Mandatory" },
  MONTHLY_CUTTOFF_DATE: { msg: "Monthly CutOff Day is Mandatory" },

  // Bank Details component Validations
  BANK_NAME_MAN: { msg: "Bank Name Is Mandatory" },
  BANK_NAME_VALIDATION: {
    msg: "Invalid BankName Format, BankName allow only Alphabets,space",
  },
  BRANCH_NAME_MAN: { msg: "Branch Name Is Mandatory" },
  BRANCH_NOT_VALID: { msg: "Branch Not Valid." },
  IFSC_MAN: { msg: "IFSC code is Mandatory" },
  // tslint:disable-next-line:max-line-length
  INVALID_IFSC: {
    msg: "Invalid IFSC Code.The IFSC is an 11-character code with the first 4 alphabetic characters representing the bank name, and the last 6 characters representing the branch. The fifth character is 0 (zero) and reserved for future use.Ex:KKBK0007631",
  },

  // tslint:disable-next-line:max-line-length
  INVALID_IFSC_IDIB: {
    msg: "Invalid IFSC Code,For Indian Bank 11-digit IFSC code and the last 7 digits will represent a specific branch code. For example Ex:IDIB000H019",
  },

  ACCOUNT_NUM_MAN: { msg: "Account Number is Mandatory" },
  ACCOUNT_NUM_NOT_VALID: { msg: "Account Number is Not Valid." },
  ACCOUNT_HOLDER_NAME_VALIDATION: {
    msg: "Invalid AccountHoldername Format, AccountHoldername allow only Alphabets,space",
  },

  // Refrence codes
  CODE_MAN: { msg: "Code is Mandatory" },
  ACCEPT_ONLY_ALPH_NUM: { msg: "Accepts only alphabets,numbers,_,&,( )." },
  NAME_MAN: { msg: "Name  is Mandatory" },
  ACCEPT_ONLY_ALPH_NUM_PLUS: { msg: "Accepts only alphabets,numbers,+,-,&." },

  // Holiday
  HOLIDAY_NAME: { msg: "Name is Mandatory" },
  HOLIDAY_NAME_VALIDATION: {
    msg: "Numbers and Special Characters are not allowed.",
  },

  // separaton in employee
  SEPERATION_MANDATORY: { msg: "Seperation Mode is Mandatory" },
  LAST_WORKING_DATE: { msg: "Last Working Date is Mandatory" },
  FINAL_DATE_OF_EXIT: { msg: "Final Date of Exit is Mandatory" },
  REMARKS: { msg: "Remarks is Mandatory" },
  REMARKS_VALIDATION: { msg: "Invalid. Accepts only characters,number" },

  // employee Basic details
  EMP_TYPE: { msg: "Employment Type is Mandatory" },
  EMP_NUMBER: { msg: "Employee Number is Mandatory" },
  EMP_DOJMAN: { msg: " Date of joining is Mandatory" },
  EMP_DOJGREATERTHANDOB: {
    msg: " Date Of Joining must be greater than Date Of Birth ",
  },
  EMP_DOSGREATERTHANDOJ: {
    msg: " Date of Seperation must be greater than Date of Joining",
  },
  EMP_FIRSTTNAME_MAN: { msg: "Employee Name is Mandatory" },
  EMP_MOBILE: { msg: "Contact Number is Mandatory" },
  EMP_DOBLESSTHANDOJ: { msg: " DateOfBirth must be less than Date of Joining" },
  EMP_CTC_COMPUTE_SUCCESS: { msg: "CTC Computed Successfully" },

  // attendance &leave

  ATTENDANCE_WORKDAYS: { msg: "Work Days is Mandatory" },
  ATTENDANCE_WORKDAYSCANTGREATERTHANMONTHDAYS: {
    msg: "Work days can't be greater than payroll month days.",
  },
  ATTENDANCE_MANANDONLYNUMS: {
    msg: "Absent Days is mandatory and Accept Numbers only ",
  },
  ATTENDANCE_ATTDAYSGREATERTHANWORKDAYS: {
    msg: "Absent days can't be greater than work days.",
  },
  ATTENDANCE_NUMWITHOUTDOT: {
    msg: "Invalid Format ,Accept Numbers and Numbers with dot (Accept only number '5' after decimal)",
  },
  ATTENDANCE_PRESENTDAYSMAN: { msg: " Present Days is Mandatory" },
  ATTENDANCE_PRESENTDAYSDAYSLESSTHANMONTHDAYS: {
    msg: " Present Days should be less than or equals to Work Days.",
  },

  LEAVE_TYPE: { msg: " Leave Type is Mandatory" },
  LEAVE_DAYS: { msg: " Leave days is mandatory and it accept only numbers." },
  LEAVEDAYSLESSTHANEQUALTOMONTHDAYS: {
    msg: "Leave days should be less than or equals to Payroll Month days.",
  },
  LEAVE_MINHALDAY: {
    msg: "Invalid leave days,minimum it accepts half day(0.5).",
  },
  LEAVE_NUMBERSWITHDECIMAL: {
    msg: "Invalid leave days,Accept Numbers and Numbers with decimal (Accept only number '5' after decimal)",
  },

  // statutory
  UAN_VALIDATION: { msg: "Allow only Numeric (12 characters only)" },
  PF_EMP_VALIDATION: {
    msg: "Invalid PF Number, First 5 Alphabet, Next 17 numbers.EX :TNMAS05411000000543219",
  },
  PF_ORG_VALIDATION: {
    msg: "Invalid PF Number, First 5 Alphabet, Next 10 numbers.EX :TNMAS0541100032",
  },
  EMP_CONTRIBUTION: { msg: "Enter valid percentage(ex:1,10,100,15.055)." },
  // tslint:disable-next-line:max-line-length
  ESI_REG_VALIDATION: {
    msg: "ESI Invalid.ESI follows a 17 digit with - code format. For explaining the format, lets take a code Ex.31-00-123456-000-0001.",
  },
  ESI_REG_Mandatory: { msg: "ESI Registration Number is Mandatory" },
  // documents
  DOC_NAME_MANDATORY: { msg: "Document Name is Mandatory" },
  DOC_TYPE_MANDATORY: { msg: "Document Type is Mandatory" },
  FILE_MANDATORY: { msg: "Please Upload File" },

  // employee master -> salary
  ANNUAL_CTC_MANDATORY: { msg: "Annual CTC is Mandatory" },
  MONTHLY_CTC_MANDATORY: { msg: "Monthly CTC is Mandatory" },
  MONTHLY_GROSS_MANDATORY: { msg: "Monthly Gross is Mandatory" },
  MONTHLY_BASIC_MANDATORY: { msg: "Basic is Mandatory" },
  EFFECTIVE_FROM_MANDATORY: { msg: "Effective From is Mandatory" },

  // configuration
  VALUE_VALIDATION: { msg: "Please enter valid format." },

  // PS-basic-details
  CONTACTNAME: { msg: "Contact Name is Mandatory" },
  DESIGNATIONMAN: { msg: " Designation is Mandatory" },

  // IT Declaration Messages

  // users
  FIRST_NAME_MANDATORY: { msg: "First Name  Mandatory" },
  FIRST_NAME_ACCEPTS_ALPHA: { msg: "First name accepts only alphabets." },
  LAST_NAME_MANDATORY: { msg: "Last Name  Mandatory" },
  LAST_NAME_ACCEPTS_ALPHA: { msg: "Last name accepts only alphabets." },

  // roles
  ROLE_NAME_MANDATORY: { msg: "Role Name is Mandatory" },
  ALLOW_ALPHA_NUM: { msg: "Name accepts only alphabets and numbers." },
  MAX_255_CHAR: { msg: "Maximum of 255 characters only" },
  DESC_ALLOW_ALPHA: { msg: "Description accepts only alphabets" },

  // Add Organization Branch Specific Validations
  NAME_VALIDATION: { msg: "Branch Name is Mandatory" },
  TYPEOFLENDER_VALIDATION: { msg: "Type Of Lender Is Mandatory" },
  SPECIALCHARACTER_VALIDATION: { msg: "Special Characters Are Not Allowed" },
  ONLYSPACEANDDOTSOMESPECIAL: {
    msg: "Only Allows Alphanumeric with space and ) , - . ( & '",
  },
  BRANCH_CODE_VALIDATION: { msg: "Branch Code is Mandatory" },
  CONTACT_NAME_VALIDATION: { msg: "Contact Name is Mandatory" },
  CONTACT_NUMBER_VALID: { msg: "Contact Number is Mandatory" },
  PT_REGISTRATION_VALID: { msg: "PT Registration Number is Mandatory" },
  PT_REGISTARTION_FORMAT_VALID: {
    msg: "PT Registartion Number Should be in Numeric",
  },

  // Statutory Based on Organization Attributes
  TAN_FORMAT_VALID: { msg: "Invalid TAN Number" },

  // Salary Components Based Attributes
  ITEM_NAME_MANDATORY: { msg: "Item Name is Mandatory" },
  SAL_CODE_MANDATORY: { msg: "Salary Code is Mandatory" },
  SAL_TYPE: { msg: "Type is Mandatory" },

  // Leave Balance Mandatory Fields
  LEAVE_TYPE_MANDATORY: { msg: "Leave Type is Mandatory" },
  OPENING_BALANE_MANDATORY: { msg: "Opening Balance is Mandatory" },

  // Arrears Validations
  ARREAR_TYPE_MANDATORY: { msg: "Arrear Type is Mandatory" },
  ARREAR_AMOUNT_MANDATORY: { msg: "Amount is Mandatory" },
  ARREAR_AMOUNT_VALIDATION: { msg: "Amount cant be zero/start with zero." },
  ARREAR_INSTALLMENTS_VALIDATION: { msg: "No. of Installments is Mandatory" },
  ARREAR_INSTALLMENTS_TYPE: {
    msg: "No.Of Installments cant be zero/start with zero.",
  },
  ARREAR_PAYSTARTDATE_VALIDATION: { msg: "Pay Start Date is Mandatory." },

  // OverTime Validations
  OVERTIME_DATE_VALIDATION: { msg: "Overtime Date is Mandatory" },
  OVERTIME_SHIFT_VALIDATION: { msg: "Shift is Mandatory" },
  OVERTIME_SHIFT_HOURS: { msg: "Hours Worked is Mandatory" },
  OVERTIME_SHIFT_INVALIDHOURS: {
    msg: "Invalid hours,Enter valid hours(allows only 1 to 24 hours).",
  },
  OVERTIME_SHIFT_INVALIDNUMBER: { msg: "Invalid Number" },
  // Reimbersments Validations
  REIMBERSMENT_TYPE_MANDATORY: { msg: "Reimbursement Type is Mandatory" },
  REIMBERSMENT_CLAIM_FROM_MANDATORY: { msg: "Claim Period From is Mandatory" },
  REIMBERSMENT_CLAIM_TO_MANDATORY: { msg: "Claim Period To is Mandatory" },
  REIMBERSMENT_CLAIM_AMOUNT_MANDATORY: { msg: "Claim Amount is Mandatory" },

  REIMBERSMENT_CLAIM_AMOUNT_UPPERLIMIT_MANDATORY: {
    msg: "Claim Amount is greater than Upper Limit",
  },

  REIMBERSMENT_CLAIM_AMOUNT_POSTIVE_MANDATORY: {
    msg: "Claim Amount is only Positive numbers",
  },
  REIMBERSMENT_BILLDATE_MANDATORY: { msg: "Bill Date is Mandatory" },
  REIMBERSMENT_BILLNUM_MANDATORY: { msg: "Bill Number is Mandatory" },
  // Loan Validations
  LOAN_TYPE_VALIDATION: { msg: "Loan Type  is Mandatory" },
  LOAN_AMOUNT_MANDATORY: { msg: "Loan Amount  is Mandatory" },

  LOAN_AMOUNT_VALIDATION: {
    msg: "Invalid Amount Should not Start with Zero and allows only 10 digits",
  },
  LOAN_RATE_VALIDATION: { msg: "Interest Rate  is Mandatory" },
  // tslint:disable-next-line:max-line-length
  LOAN_RATE_VALIDATION_TYPE: {
    msg: " Invalid Intrest ,Accept Numbers and Numbers with decimal (Accept only two digits after decimal)(ex:-2,10)",
  },
  LOAN_START_DATE: { msg: "Start Date  is Mandatory" },
  LOAN_INSTALLMENT: { msg: "Installments  is Mandatory" },

  LOAN_INSTALLMENT_VALIDATION_TYPE: {
    msg: "Invalid Amount Should not Start with Zero ",
  },
  LOAN_COMPLETION_DATE: { msg: "Completion Date  is Mandatory" },

  // Job Validations
  JOB_NAME_VALIDATION: { msg: "Name is Mandatory" },
  JOB_SCHEDULE_VALIDATION: { msg: "Schedule is Mandatory" },
  JOB_RECIPIENT_VALIDATION: { msg: "Recipient Role is Mandatory" },
  JOB_SUBJECT_VALIDATION: { msg: "Subject is Mandatory" },
  JOB_REMARKS_VALIDATION: { msg: "Remarks is Mandatory" },
  JOB_NAME_PATTERN: { msg: "Name Should Be in Alpha Numeric" },

  // Work Flow Validations
  WORKFLOW_VALIDATION: { msg: "Actor is Mandatory" },
  // PAN FORMATS
  PAN_C_FORMAT: {
    msg: "Invalid PAN (4th character [C — Company] Ex: AAACZ1234C)",
  },
  PAN_P_FORMAT: {
    msg: "Invalid PAN (4th character [P — Individual or Person ]  Ex: AAAPZ1234C)",
  },
  PAN_F_FORMAT: {
    msg: "Invalid PAN (4th character [F — Firm ]  Ex: AAAFZ1234C)",
  },
  PAN_T_FORMAT: {
    msg: "Invalid PAN (4th character [T — Trust(AOP) ] Ex: AAATZ1234C)",
  },

  PAN_A_FORMAT: {
    msg: "Invalid PAN (4th character [A — Association of persons (AOP)] Ex: AAAAZ1234C)",
  },
  PAN_B_FORMAT: {
    msg: "Invalid PAN (4th character [B — Body of individuals (BOI) ]  Ex: AAABZ1234C)",
  },
  PAN_G_FORMAT: {
    msg: "Invalid PAN (4th character [G — Government ]  Ex: AAAGZ1234C)",
  },
  PAN_H_FORMAT: {
    msg: "Invalid PAN (4th character [H — HUF (Hindu undivided family) ] Ex: AAAHZ1234C)",
  },
  PAN_L_FORMAT: {
    msg: "Invalid PAN (4th character [L — Local Authority ] Ex: AAALZ1234C)",
  },
  PAN_J_FORMAT: {
    msg: "Invalid PAN (4th character [J — Artificial juridical person] Ex: AAAJZ1234C)",
  },

  // Tickets Validation

  SUBJECT_VALIDATION: { msg: "Subject is Mandatory" },
  ATTACHEMENT_VALIDATION: { msg: "Please Add Attachement" },
};
