import { TranslateCodeToValue } from 'src/app/shared/pipes/translateCodeToValue.pipe';
import { MessageConstants } from './services/message-constants';
import { TranslateEmpIDToNumberAndName } from './shared/pipes/translateEmpIDToNumberAndName.pipe';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';
import { IndependentSharedService } from 'src/app/services/independentShared.service';
import { element } from 'protractor';
import { LoginComponent } from './login/login.component';
import { ToasterService } from './services/toaster.service';
import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  loader;

  constructor(private independentSharedService: IndependentSharedService, private loadingService: LoadingService, private _authService: AuthService, private _translateEmpIDToNumberAndName: TranslateEmpIDToNumberAndName, private _translateCodeToValue: TranslateCodeToValue) {
    this.loader = this.loadingService;
  }

  ngOnInit() {
    // this.independentSharedService.handleError("error form modal")

    // console.log('code t ovalue usage===>', this._translateCodeToValue.transform('states', 'AP'));
    // console.log('this._translateEmpIDToNumberAndName.transform', this._translateEmpIDToNumberAndName.transform('EmpIDNamePair', '195'));
    timer(5000, 420000).subscribe(val => this._authService.checkTokenExpiry());
    // to test refersh token
    // setTimeout(() => {
    //   this._authService.prepareRefreshTokenObj();
    // }, 20000);


  }


}

