import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeMasterService {
  emp;
  selectedRowDataEmp;
  selectedNewRowDataEmp;
  selectedEmpFromSearch;
  emprowdata;

  employeeChanged = new Subject();
  employeeSearchcardClear = new Subject();
  clearDoc = new Subject();
  basicDetailsTabChanged = new Subject();
  empSalaryEffectiveFrom: any;
  // by=(SB)
  public selectedYear;
  public selectedMonth;
  public EmpByIdDetails;
  public val;
  constructor() { }
  // By(SB)
  setpayslipYear(val) {
    this.selectedYear = val;
    this.employeeChanged.next(val);
  }
  getpayslipYear() {
    return this.selectedYear;
  }
  setpayslipMonth(val) {
    this.selectedMonth = val;
    this.employeeChanged.next(val);
  }
  getpayslipMonth() {
    return this.selectedMonth;
  }
  //  end Here


  setEmp(val) {
    this.selectedRowDataEmp = val;
    this.employeeChanged.next(val);
  }
  getEmp() {
    return this.selectedRowDataEmp;
  }



  setEmpRowData(emprowdata) {
    this.emprowdata = emprowdata;
  }
  getEmpRowData() {
    return this.emprowdata;
  }
  newSetEmp(val) {
    this.selectedNewRowDataEmp = val;
  }
  newGetEmp() {
    return this.selectedNewRowDataEmp;
  }

  setSelectedEmpFromSearch(employee) {
    this.selectedEmpFromSearch = employee;
  }
  getSelectedEmpFromSearch() {
    return this.selectedEmpFromSearch;
  }

  clearSelectedSearchCard() {
    this.employeeSearchcardClear.next();
  }

  clearDocument() {
    this.clearDoc.next();
  }

  setEmpSalaryEffectiveFrom(effectiveFrom) {
    this.empSalaryEffectiveFrom = effectiveFrom;
  }
  getEmpSalaryEffectiveFrom() {
    return this.empSalaryEffectiveFrom;
  }

  employeeBasicDetailsTabChanged() {
    this.basicDetailsTabChanged.next();
  }

  setEmpByIdDetails(val) {
    return this.EmpByIdDetails = val;
  }
  getEmpByIdDetails() {
    return this.EmpByIdDetails;
  }

}
