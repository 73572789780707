import { LoadingService } from '../../../services/loading.service';
import { ToasterService } from '../../../services/toaster.service';
import { HttpClientService } from '../../../services/httpClient.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ACLConstants } from 'src/app/services/acl-constants';


@Component({
  selector: 'app-imports-file',
  templateUrl: './imports-file.component.html',
  styleUrls: ['./imports-file.component.css']
})
export class ImportsFileComponent implements OnInit {

  ImportForm: FormGroup;
  fileToUpload: any;
  RequestTypes: any;
  submitted = false;
  public showUploadType = false;
  public showText;
  public selectedTargetUrl;
  public JSON;

  @Input() ImportRequesType = 'All';
  importInstructions;
  selectedImportType: any;
  permissions: any;
  public ACLConstants = ACLConstants;


  // to display template Url based on the type selection
  showTemplateUrl(event) {
    const val = JSON.parse(event.target.value);
    if (val) {
      this.showText = true;
      this.selectedTargetUrl = '../../../..' + val.TemplateURL;
    }
    if (val.Name == 'Attendance' || val.Name == 'Employee Basic Details') {
      this.showUploadType = true;
    } else {
      this.showUploadType = false;
    }
  }

  // upload file and checking file size
  uploadFile(files) {
    if (files[0].size > 10485760) {
      this._toasterService.error('File Size should be less than 10MB.');
      return;
    } else {
      this.fileToUpload = files.item(0);
      this.ImportForm.patchValue({ UploadFile: files });
    }

  }

  // to delete selected attachment
  deleteAttachment() {
    this.fileToUpload = '';
    this.ImportForm.patchValue({ UploadFile: null, uploadType: 'new' });
  }

  // upload File FormSubmit method
  uploadFileFormSubmit() {
    console.log('ImportForm', this.ImportForm.value);
    const formData = new FormData();
    this.submitted = true;
    if (this.ImportForm.valid) {
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
      formData.append('type', JSON.parse(this.ImportForm.value.requestType).Name);
      formData.append('uploadType', this.ImportForm.value.uploadType);
      formData.append('template_id', JSON.parse(this.ImportForm.value.requestType).ID);
      this._loadingService.loaderStart();
      // formData.append('description', this.ImportForm.value.description);
      this._httpClientService
        .post('import', formData, 'apiDevBaseUrlImpex')
        .subscribe(uploadRes => {
          console.log('file upload response', uploadRes);
          this._loadingService.loaderEnd();
          this._toasterService.success('Your request is in progress. Please check the status in import history.');
          this.fileToUpload = null;
        },
          uploadErr => {
            console.log('file upload Error', uploadErr);
            this._loadingService.loaderEnd();
            this._toasterService.error(uploadErr.error.m);
          }
        );
    }
  }

  // To get import Request Types
  getRequestTypes() {
    this._httpClientService
      .get('import-types?category=' + this.ImportRequesType, 'apiDevBaseUrladmin')
      .subscribe((requestTypeRes: any) => {
        console.log('requestTypeRes', requestTypeRes);
        this.RequestTypes = requestTypeRes.d;
        let earRew = [];
        for (let i = 0; i < this.RequestTypes.length; i++) {

          if (this.RequestTypes[i].Name == 'Holidays') {
         
            console.log('Holiday Matched')
          } else {
            console.log('Holiday NOtMatched')
            earRew.push(this.RequestTypes[i]);
          }
        }
        this.RequestTypes = earRew;


      });
  }

  // open read me pop up

  openReadMePopUp() {
    this.selectedImportType = JSON.parse(this.ImportForm.value.requestType).Name;

    document.getElementById('viewReadMe').click();
  }


  constructor(
    private _fb: FormBuilder,
    private _httpClientService: HttpClientService,
    private _toasterService: ToasterService,
    private _loadingService: LoadingService,

  ) {
    this.JSON = JSON;
    this.ImportForm = this._fb.group({
      requestType: ['', Validators.required],
      uploadType: ['new'],
      UploadFile: ['', Validators.required]
      //  description: ['', Validators.required],

    });
  }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("Permissions"));
    this.getRequestTypes();
    this.importInstructions = {
      'Reference Codes': [{ name: 'Field Code Category is Mandatory' },
      { name: 'Field Code is Mandatory' },
      { name: 'Field Value Code is Mandatory' },
      { name: 'Field Value Description is Mandatory' }],
      'Employee Basic Details': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Employment Type is Mandatory' },
        { name: 'First Name is Mandatory' },
        { name: 'Gender is Mandatory', 'sub': ['Male', 'Female'] },
        { name: 'Date Of Birth is Mandatory' },
        { name: 'Date Of Joining is Mandatory' },
        { name: 'Country is Mandatory' },
        { name: 'State is Mandatory' },
        { name: 'City is Mandatory' },
        { name: 'Employee Name  is Mandatory' },
        { name: 'Employee Age  is Manadatory' }],

      'Employee Individual Salary': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Effective From is Mandatory' }],
      'Employee Statutory': [
        { name: 'Employee Number is Mandatory' }],

      'Employee Payment Info': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Payment Type is Mandatory', 'sub': ['cheque', 'banktransfer', 'cash'] }],

      'Attendance': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Payroll Year is Mandatory' },
        { name: 'Payroll Month is Mandatory' },
        { name: 'Work Days is Mandatory' },
        { name: 'Present Days is Mandatory' },
        { name: 'Absent Days is Mandatory' }],
      'Leave': [
        {
          name: 'Leave Type is Mandatory', 'sub': [
            'S - Sick Leave',
            'M - Maternity Leave',
            'C - Casual Leave',
            'E - Earned Leave']
        },
        { name: 'Leave Days is Mandatory' }],

      'Leave Balances': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Year is Mandatory' },
        { name: 'Leave Type is Mandatory' },
        { name: 'Opening Balance is Mandatory' }],

      'Income Tax Inputs': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Income Tax Regime is Mandatory' },
        { name: 'FY is Mandatory' },
        { name: 'Hints:', 'sub': ['Y Indicates YES', 'N Indicates NO'] }
      ],
      'Arrears': [
        { name: 'No of Installments is Mandatory' },
        { name: 'Pay Start Date is Mandatory' }],

      'Overtime': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Overtime Date is Mandatory' },
        { name: 'Shift is Mandatory' }],

      'Reimbursement': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Reimbursement Type is Mandatory' },
        { name: 'Bill Date is Mandatory' },
        { name: 'Bill Number is Mandatory' },
        { name: 'Claim Period From is Mandatory' },
        { name: 'Claim Period To is Mandatory' },
        { name: 'Claim Amount is Mandatory' }],

      'Employee Salary Adjustments': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Payout Year is Mandatory' },
        { name: 'Payout Month is Mandatory' }],

      'Employee Individual Salary Revision': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Effective From is Mandatory' },
        { name: 'Payout From Date is Mandatory' }],

      'Employee Separation': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Seperation Mode is Mandatory', 'sub': ['RESIGNED', 'CONTRACT EXPIRY', 'DEPORTED', 'EXPIRED'] },
        { name: 'Remarks is Mandatory' }],

      'Onboarding New Org Employee Monthly Payment History': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Payroll Year is Mandatory' },
        { name: 'Payroll Month is Mandatory' }],

      'Onboarding New Org Employee Yearly Payment History': [
        { name: 'Employee Number is Mandatory' },
        { name: 'Financial Year' }]
    }


  }

}
