import { AuthService } from './../../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, HostListener, } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { Router } from '@angular/router';
import { JobService } from './../../.././../services/jobs-service';

//./../../../../services/job-service


@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {
  loggedInUser;
  accessToken;
  refreshToken;
  entitie;
  tokenConfirmation;
  userNotConfired;
  timerStart = 300;
  counter;

  // full screen
  isfullscreen = false;
  count;
  key;
  toggleClass = 'ft-maximize';
  link: any;
  countmessageTasksdata=0;





  // @HostListener('document:fullscreenchange', ['$event'])
  // @HostListener('document:webkitfullscreenchange', ['$event'])
  // @HostListener('document:mozfullscreenchange', ['$event'])
  // @HostListener('document:MSFullscreenChange', ['$event'])
  // fullscreenmode() {

  //   if (this.toggleClass == 'ft-minimize') {
  //     this.toggleClass = 'ft-maximize';
  //     this.isfullscreen = false;
  //   }
  //   else {
  //     this.toggleClass = 'ft-minimize';
  //     this.isfullscreen = true;
  //   }
  // }


  logout() {
    console.log('logout clicked');
    this._authService.logout();
  }

  openFullscreen() {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
    //this.isfullscreen = false;




  }

  closefullscreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
      // this.isfullscreen = false;
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    //this.isfullscreen = true;
  }



  // copyToClipboard
  copyStringToClipboard(str, type) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    if (type == 'accessToken') {
      el.value = 'Bearer ' + str;
    } else {
      el.value = str;
    }
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    // el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }

  // extendSession if user click on okay.
  extendSession() {
    this._authService.tokenConformationFromUser.next();
    this.tokenConfirmation = false;
    // stoping counter
    clearInterval(this.counter);

    clearTimeout(this.userNotConfired);
  }

  // counter
  counterStart() {
    this.counter = setInterval(res => {
      //  if timerStart will reaches  to 0
      // if (this.timerStart === 0) {
      //   return clearInterval(this.counter);
      // }
      this.timerStart--;
      console.log(this.timerStart);
    }, 1000
    );
  }


  // logout  if user not click on okay. means no refresh
  checkUserConfirmation() {
    this.counterStart();
    this.userNotConfired = setTimeout(res => {
      clearInterval(this.counter);
      this._authService.logout();
    }, 5 * 60 * 1000); // 5mins as milliseconds
  }

  // getMessageCenterList() {

  //   try {
  //     this._loader.loaderStart();
  //     console.log('get list');
  //     // messages?read=true&preserve=true&start=2020-05-06&end=2020-06-11
  //     this._httpClientService
  //       .get('messages?' + 'read=' + false +
  //         '&preserve=' + false
  //         + '&start=' + null
  //         + '&end=' + null, 'apiDevBaseUrlMsgCenter').subscribe(
  //           (messageRes: any) => {

  //             this._loader.loaderEnd();
  //             console.log('get  nav list', this.count);
  //             // for(var i = 0; i < messageRes.length; i++) {
  //             // this.count = messageRes.d.length;

  //             // }
  //             // console.log('count', this.count, messageRes.d, messageRes.d.length);
  //           },

  //           (err: any) => {

  //             // this.messageRowData = null;
  //             this._loader.loaderEnd();
  //             // this._toasterService.error(err.error.m);

  //           }
  //         );
  //   } catch (err) {
  //     this._loader.loaderEnd();
  //     this._toasterService.error(err);
  //   }
  // }


  getCompanyLogoByFileName(logo) {
    try {
      this._loader.loaderStart();
      this._httpClientService
        .get('docs/' + logo, 'apiDevBaseUrlsys')
        .subscribe((getLogoByClientIdRes: any) => {
          this._loader.loaderEnd();
          this.link = getLogoByClientIdRes.d;
        },
          (err: any) => {
            this._loader.loaderEnd();
            this._toasterService.error(err.error.m);
          }
        );
    } catch (err) {
      this._loader.loaderEnd();
      this._toasterService.error('Something went wrong.Please try again');
    }

  }

  onClickAll(){
    this.router.navigate(['/organization/jobs/message-center'])
  }

  constructor(
    private _authService: AuthService,
    private router: Router,
    private _loader: LoadingService,
    private _toasterService: ToasterService,
    private _httpClientService: HttpClientService,
    public _jobservice:JobService) { }

  ngOnInit() {

    this.loggedInUser = JSON.parse(localStorage.getItem('logged_in_user'));
    console.log('logged_in_user ', this.loggedInUser);
    this.accessToken = localStorage.getItem('access_token');
    this.refreshToken = localStorage.getItem('refreshtoken');
    this.entitie = JSON.parse(localStorage.getItem('entitie'));
    this._authService.isTokenGoingToExpiry.subscribe(res => {
      this.tokenConfirmation = true;
      // if user not clicked on okay then logout the user  from application
      this.checkUserConfirmation();
    });
   // this.getMessageCenterList();


    // get company logo
    let fileName = this.entitie.Logo;
    if (this.entitie.Logo) {
      this.getCompanyLogoByFileName(fileName);
    }
    console.log('companyLogo', fileName);

  //  this._jobservice.getMessageCenterList()

  this._jobservice.getAllSubscribeEvents();
  this._jobservice.totalMessageTasksSubject.subscribe((res:any) =>{
    this.countmessageTasksdata = res.length;

  })
  console.log("this.countmessageTasksdata#####",this.countmessageTasksdata);
    
    //Adding the Interval for auto refresh

    setInterval(()=> { this._jobservice.getAllSubscribeEvents(); }, 5*60*1000);

  }




}
