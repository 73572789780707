import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

import {LicenseManager} from "ag-grid-enterprise/dist/lib/licenseManager";

LicenseManager.setLicenseKey("CompanyName=VPD Consultants PVT. LTD.,LicensedApplication=vpdpaysstar,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-011379,ExpiryDate=2_November_2021_[v2]_MTYzNTgxMTIwMDAwMA==5f2ac06e6ca3557372a80995fd7a5a29");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
