// The file contents constant values for ACL
export const ACLConstants = {

  showConfidentialAttributes: 'show-confidential-attributes',

  //Reimbursments
  empReimbursmentAddWrite: 'emp-create-reimbursement:W',
  empReimbursmentUpdateWrite: 'emp-update-reimbursement:W',
  empReimbursmentDeleteWrite: 'emp-delete-reimbursement:D',

  // Org sal components
  orgNewsalWrite: 'org-post-payroll-salcomponents:W',
  orgupdateWrite: 'org-update-payroll-salcomponents:W',
  orgsaldelete: 'org-delete-payroll-salcomponents:D',

  // RESET
  orgsalReset:'reset-payroll-components:D',

 //  Salary Components >> Location/Branch
  orglocalReset:'org-delete-location-components:D',
  orglocalAdd:"post-location-components:W",
  orglocsalEdit: 'update-location-components:W',
  orglocsalDelete: 'delete-location-components:D',

  // loan Components
  loandelete: 'emp-delete-loan:D',
  loanupdateWrite: 'emp-update-loan:W',
  loaninsertwrite: 'emp-insert-new-loan:W',

  //org-BasicDetails -Client
  OrgUpdateOrgDetailsWrite: 'admin-update-client:W',

  // leave Settings 
  orgCreateLeaveSettingsWrite: 'create-leave-balance:W',
  orgUpdateLeaveSettingsWrite: 'update-leave-balance:W',
  orgLeaveSettingsDelete: 'delete-emp-leave:D',
  orgImportLeaveSettingsWrite: 'emp-import-balance-leaves:W',

  // Loan Settings 
  loanUpperLimitSave: 'emp-post-loan-upperlimits:W',
  // emp-list-loansupperlimits:R

  // emp-post-loanupperlimits:W
  // org Reimbursement settings

  orgUpdateSalCompWrite: 'org-update-client-salary-components:W',
  orgCreateSalCompWrite: 'org-create-salary-component:W',
  //orgDeleteSalCompWrite:'org-delete'


  // org Bank details-Policy settings
  orgCreteBankDetailsWrite: 'org-create-org-bank-details:W',
  orgCreteBankDetailsRead: 'org-get-org-bank-details:R',
  orgDeleteBankDetails: 'org-delete-org-bank-record:D',
  orgUpdateBankDetailsWrite: 'org-update-org-bank-details:W',

  // org branch-Policy settings
  orgGetBranchRead: 'org-create-orgbranches:W',
  orgCreateBranchWrite: 'org-create-orgbranches:W',
  orgUpdateBranchWrite: 'org-update-orgbranches:W',
  orgDeleteBranch: 'org-delete-orgbranches:D',


  // Org Statutory -Policy settings
  orgCreateStatutoryWrite: 'org-create-orgstatutory:W',



  // emp-no--series------
  empEmpNumSeriesCreateWrite: 'emp-client-create-emp-num-series:W',
  empEmpNumSeriesDelete: 'emp-client-delete-emp-num-series:D',
  empEmpNumSeriesUpdateWrite: 'emp-client-update-emp-num-series:W',



  // emp-employee-----------------
  empAddWrite: 'emp-add-employee:W',
  empClientAddWrite: 'emp-client-add-employee:W',
  empClientUpdateEmployeeWrite: 'emp-client-update-employee:W',
  empFilterEmployeeRead: 'emp-filter-employees:R',
  empGetEmployeeRead: 'emp-get-employees:R',
  empImportEmployeeWrite: 'emp-import-employee:W',
  empListEmployeeIdEmpNumberRead: 'list-employees-id-empnumber-pair:R',
  empOrgListOfEmpDetailsRead: 'org-list-of-employee-details:R',
  empOrgListOfEmpPayrollRead: 'org-list-of-employee-payrolls:R',

  // emp Overtime Reg---------------------
  empCreateOTRWrite: 'emp-create-overtime-register:W',
  empDeleteOTR: 'emp-delete-overtime-register:D',
  empEMpIdOTRRead: 'emp-get-overtime-empid-otdate:R',
  empGetOTRRead: 'emp-get-overtime-register:R',
  empUpdateOTRWrite: 'emp-update-overtime-register:W',
  empImportOTRWrite: 'emp-import-overtimes:W',





  // emp-Arrears-----------------------

  empArrearsCreateWrite: 'emp-create-arrears:W',
  empArrearsDelete: 'emp-delete-arrear:D',
  empArrearsGetRead: 'emp-get-arrears:R',
  empArrearsUpdateWrite: 'emp-update-arrear:W',
  empArrearsImportWrite: 'emp-import-arrear:W',

  // // emp - overtime registration
  // empCreateOTRRead: 'emp-create-overtime-register:R',
  // empOTRDelete: 'emp-delete-emp-overtime-register:D',
  // empGetOTRRead: 'emp-get-emp-overtime-register:R',
  // empIdOTRRead: 'emp-get-overtime-empid-otdate:R',
  // empImportOTRWrite:'emp-import-overtimes:W',
  // empUpdateOTRWrite: 'emp-update-overtime-register:W',

  // emp-Leave--------------------

  empLeaveCreateWrite: 'emp-create-leave:W',
  empLeaveDelete: 'emp-delete-emp-leave:D',
  empLeaveGetRead: 'emp-get-emp-leave:R',
  empLeaveImportBalWrite: 'emp-import-balance-leaves:W',
  empLeaveImportWrite: 'emp-import-leaves:W',
  empLeaveListRead: 'emp-list-leaves:R',
  empLeaveUpdateWrite: 'emp-update-leave:W',
  empGetLeave: 'get-emp-leave:R',
  empLeaveBalListRead: 'list-leaves-balance:R',
  empLeaveBalDeleteWrite: 'update-leave-balance:W',

  // emp-Attendance----------------------

  empAttendanceCreateWrite: 'emp-create-attendance:W',
  empAttendanceDelete: 'emp-delete-emp-attendance:D',
  empAttendanceGetRead: 'emp-get-emp-attendance:R',
  empAttendanceImportWrite: 'emp-import-attendance:W',
  empAttendanceListRead: 'emp-list-attendance:R',
  empAttendanceUpdateWrite: 'emp-update-attendance:W',




  //emp-documents-----------------------

  empListDocuments: 'emp-list-docs:R',
  empGetDocument: 'emp-Get-doc:R',
  empGetDownloadDocument: 'emp-download-doc:R',
  empAddDocument: 'emp-add-doc:W',
  empListDocument: 'emp-list-docs-doctype:R',
  empDeleteDocument: 'emp-delete-doc:D',

  // Holidays-Policy Settings
  orgCreateNewHoliday: 'org-create-new-holiday:W',
  orgCreateDeleteHoliday: 'org-delete-holiday:D',
  orgHolidayGetById: 'org-get-holidays-byID:R',
  orgUpdateHoliday: 'org-update-holiday:W',

  // // Pay-schedule-Policy Settings
  orgCreatePayschedule: 'org-create-payschedule:W',
  orgGetPayschedule: 'org-get-payschedule:R',
  orgUpdatePayscheduleStatus: 'org-update-payschedule-status:W',
  orgUpdatePayschedule: 'org-update-payschedule:W',

  // checklist
  orgSavePayrollCheckListItems: 'org-save-payroll-checklist-items:W',
  orgListPayrollCheckListItems: 'org-list-payroll-checklist-items:R',


  //policy settings->>>workflows-Policy Settings

  orgListAll: 'org-list-all-workflows:R',
  orgGetWorkflowByClient: 'org-get-workflow-by-client:R',
  orgPostWorkflowEvents: 'org-post-workflow-events:W',
  orgPutWorkFlow: 'org-put-workflow:W',
  orgDeleteWorkflow: 'org-delete-workflow:D',

  // employee master->>> salary details tab
  orgListPayrollComponents: 'org-list-payroll-components:R',
  empListCTCCOMPONENTS: 'emp-list-CTC-components:R',
  empCreateCTC: 'emp-create-ctc:W',
  empHoldSalary:'emp-update-status:W',
  empStopSalary:'emp-update-status:W',


  //users
  sysAddOrgUser: 'sys-add-org-user:W',
  sysUpdateOrgUser: 'sys-update-org-user:W',
  sysLockOrgUser: 'sys-lock-org-user:W',
  sysListOrgUsers: 'sys-list-org-users:R',
  sysInviteUser: 'sys-invite-user:W',
  sysAssignOrgToUser: 'sys-assign-org-to-user:W',
  sysAssignRoleToUser: 'sys-assign-role-to-user:W',
  sysDeleteOrgToUser: 'sys-delete-org-to-user:D',
  // sys-check-users-exist-for-given-role:R: "R"

  //roles
  sysAddRole: 'sys-add-role:W',
  sysListRoles: 'sys-list-roles-byType:R',
  // configuration(policy settings)
  orgUpdateOrgConfiguration: 'org-update-org-configuration:W',
  // reference codes(fieldvalues)

  fieldValuePostPermision: 'admin-create-fieldvalue:W',
  fieldValuePutPermision: 'admin-update-fieldvalues-by-fieldcode:W',
  // ESS Portal Invite
  empInviteEmployee: 'emp-invite-employee:W',
  empDisableEmployee:'employee-ess-disable:W',
  empHoldSalaryEmployee:'emp-update-payment-status:W',
  empStopSalaryEmployee:'emp-update-payment-status:W',
  
  // show-confidential-attributes: "R"
  showConfidential:'show-confidential-attributes: R',

  //leave balance
  leavebalancewrite:'create-leave-balance:W',
  leavebalancedelete:'delete-emp-leave-balance:D',
  leavebalanceread:'list-leaves-balance:R',
  leavebalanceupdate:'update-leave-balance:W',
  leaveimportbalance:'emp-import-balance-leaves:W',

  // payroll exections
  createNewBatch:'org-create-new-batch:W',
  viewBatchInfo:'org-payroll-process-supplementary-employees:R',

  // Policy Settings >> ITConfiguration >> perquisites
  perquisitesAdd:'org-post-perquisites:W',
  perquisitesDelete:'org-purge-perquisites-bykey:D',

  // Policy Settings >> ITConfiguration >> exemptions
  exemptionsAdd:'org-post-perquisites:W',
  exemptionsDelete:'org-purge-perquisites-bykey:D',

  // Salary Adjustments
  empSalAdjustment:'emp-salary-adjustments:W',
  
  // Full & Final Settlement
  empFullFinalSettlement:'emp-trigger-FF-events:W',

  // Approve It Declaration
  approveItDeclaration:'emp-post-submitted-status:W',
  addNewItDeclaration:'emp-IT-WF-event:W',

  // release It Declaration
  releaseItDeclaration:'emp-is-itdec-locked:W',

  // Payout release
  releasePayout:'org-update-payout-status:W',

  // imports
  importRecords:"import-entities:W",
  saveCorrection:'put-importrows:W',
  reImport:'post-importrows:W',

  // jobs
  runNow:'post-job-run:W',
  changeSchedule:'modify-scheduler:W',
  addAlerts:'create-alert:W',
  editAlerts:'update-alert:W',
  deleteAlerts:'delete-alert:D',
  
  // tickets
  addTickets:'create-ticket:W',
  editTickets:'update-ticket:W',
  reOpen:'update-reopen-ticket:W',


};

