import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ITStatementComponent } from './orgConsole/modules/payroll/income-tax/itoutput/it-statement/it-statement.component';
import {
  AuthService as AuthGuard
} from './services/auth.service';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent,canActivate: [AuthGuard] },
  {
    path: 'organization',
    loadChildren: './orgConsole/organization.module#OrganizationModule'
  },
  { path: 'sp', loadChildren: './ppConsole/pp.module#PpModule' },
  {path:'IT-Statement', component: ITStatementComponent ,canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'  // scroll to top when route changed
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
