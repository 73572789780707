import { ToasterService } from 'src/app/services/toaster.service';
import { LoadingService } from 'src/app/services/loading.service';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CommonMethodsService {

  constructor(private _httpClientService: HttpClientService,
    private _loadingService: LoadingService,
    private _toasterService: ToasterService) { }

  downloadCSV(url, fileName, obj) {
    this._loadingService.loaderStart();
    this._httpClientService.downloadCSVPost(url, obj, 'apiDevBaseUrlorg').subscribe(
      (success: HttpResponse<Blob>) => {
        console.log('success download res', success);
        this._loadingService.loaderEnd();
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(success.body, fileName);
        } else {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(success.body);
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this._toasterService.success('Downloaded successfully.');

        }
      },
      err => {
        console.log('err  ', err);
        this._loadingService.loaderEnd();
        this._toasterService.error('Server error while downloading file.');
      }
    );
  }

  //Download Imports Error Logs
  downloadImportCSV(url, fileName, obj) {
    this._loadingService.loaderStart();
    this._httpClientService.downloadCSVPost(url, obj, 'apiDevBaseUrlImpex').subscribe(
      (success: any) => {
        console.log('success download res', success);
        this._loadingService.loaderEnd();
        var blob = new Blob([success], { type: 'text/csv' });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this._toasterService.success('Downloaded successfully.');

        }
      },
      err => {
        console.log('err  ', err);
        this._loadingService.loaderEnd();
        this._toasterService.error('Server error while downloading file.');
      }
    );
  }
}
