import { PipesModule } from './shared/pipes/pipes.module';
import { TokenInterceptorService } from "./interceptors/auth.interceptor";
import { IndependentSharedService } from "./services/independentShared.service";
import { LoginComponent } from "./login/login.component";

import { LayoutModule } from "./shared/modules/layout/layout.module";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutingModule } from "./app.routing.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { ToasterService } from "./services/toaster.service";
import { ToastrModule } from "ngx-toastr";

// HttpClient module for RESTful API
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

//Material
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
// import { AgGridModule } from '@ag-grid-community/angular';
import { AgGridModule } from "ag-grid-angular/main";
import { SharedModule } from "./shared/shared.module";

// To decode JWt token
import { JwtModule } from "@auth0/angular-jwt";
import { LocationStrategy, HashLocationStrategy, DecimalPipe } from '@angular/common';
import { currencyWithoutDecimalPipe, ITStatementComponent } from './orgConsole/modules/payroll/income-tax/itoutput/it-statement/it-statement.component';

// pipes

// import { QueryBuilderModule } from "angular2-query-builder";
@NgModule({
  declarations: [AppComponent, LoginComponent, ITStatementComponent ,currencyWithoutDecimalPipe],
  imports: [
    //QueryBuilderModule, 
    AgGridModule.withComponents([]),
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    MatTabsModule,
    MatMenuModule,
    SharedModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    }),
    PipesModule
  ],
  providers: [
    DecimalPipe,
    ToasterService,
    IndependentSharedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}
