import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { ToasterService } from 'src/app/services/toaster.service';
import * as _ from 'lodash';
import { TranslateCodeToValue } from '../../pipes/translateCodeToValue.pipe';
import { MasterDataService } from 'src/app/services/masterData.service';

@Component({
  selector: 'app-select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.css']
})
export class SelectStateComponent implements OnInit {
  // Varibles
  public selectStatesRowData;
  public clientProviderList;
  public statesList;
  public targetValue;
  public state;
  @Output() selectedState = new EventEmitter();

  selectStatesChange(event) {
    console.log('getselectState', event.target.value);
    this.targetValue = event.target.value;
    this.selectedState.emit(this.targetValue);
  }


  // get orgnization state list
  getClientByProviderId() {
    try {
      this._loader.loaderStart();
      this._httpClientService
        .get('clients/' + JSON.parse(localStorage.getItem('entitie')).OrgId, 'apiDevBaseUrladmin')
        .subscribe(
          (clientProviderRes: any) => {
            this.clientProviderList = clientProviderRes.d;
            this.getSelectedBranchStates().subscribe((selectStateDataRes: any) => {
              console.log('selectStateDataRes=', selectStateDataRes.d);
              if (selectStateDataRes.d) {
                // Organization get state object
                const preparedObject = this.clientProviderList;
                const orgObj = [];
                // prepared Organization Object
                const preparedObjectOrganization = {
                  stateCode: preparedObject.addrState,
                  stateDescription: this._translateCodeToValuePipe.transform('states', preparedObject.addrState),
                  country: preparedObject.addrCountry,
                };
                orgObj.push(preparedObjectOrganization);

                console.log('preparedObject=', preparedObjectOrganization);




                // get branch states
                const masterFormatte = [];
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < selectStateDataRes.d.length; i++) {
                  const obj = {
                    stateCode: selectStateDataRes.d[i].State,
                    stateDescription: this._translateCodeToValuePipe.transform('states', selectStateDataRes.d[i].State),
                    country: selectStateDataRes.d[i].Country
                  };
                  masterFormatte.push(obj);
                }
                console.log('masterFormatte=', masterFormatte);

                // Duplicate Removing
                var uniq = _.uniqBy(masterFormatte.concat(orgObj), o => {
                  return o.stateCode;
                });

                this.selectStatesRowData = uniq;
                this.state = this.selectStatesRowData[0].stateCode + '_' + this.selectStatesRowData[0].country;
                console.log('this.state+++++++++++++++++', this.state);


                this._masterService.setSelectedState(this.selectStatesRowData[0].stateCode);
                this._masterService.stateParameterChanged.next(this.selectStatesRowData[0].stateCode);
                let stateget = this._masterService.getSelectedState();
                console.log("this._masterService.getSelectedState();", stateget);
                this.selectedState.emit(this.state);
                console.log("state", this.state);



              } else {

                // Organization get state object
                const preparedObject = this.clientProviderList;
                const orgObj = [];
                // prepared Organization Object
                const preparedObjectOrganization = {
                  stateCode: preparedObject.addrState,
                  stateDescription: this._translateCodeToValuePipe.transform('states', preparedObject.addrState),
                  country: preparedObject.addrCountry,
                };
                orgObj.push(preparedObjectOrganization);

                console.log('preparedObject=', preparedObjectOrganization);

                this.selectStatesRowData = orgObj;
                this.state = this.selectStatesRowData[0].stateCode + '_' + this.selectStatesRowData[0].country;

                console.log('this.state+++++++++++++++++', this.state);

                this._masterService.setSelectedState(this.selectStatesRowData[0].stateCode);
                this._masterService.stateParameterChanged.next(this.selectStatesRowData[0].stateCode);
                let stateget = this._masterService.getSelectedState();
                console.log("state iin service", stateget);



              }
            });

            this._loader.loaderEnd();

          },
          (err: {}) => {
            this._loader.loaderEnd();
            this._toasterService.error(err);
          }
        );
    } catch (err) {
      this._loader.loaderEnd();
      this._toasterService.error(err);
    }
  }
  getSelectedBranchStates() {
    return this._httpClientService
      .get('orgbranches', 'apiDevBaseUrlorg');

  }
  constructor(
    // tslint:disable-next-line: variable-name
    private _loader: LoadingService,
    // tslint:disable-next-line: variable-name
    private _httpClientService: HttpClientService,
    // tslint:disable-next-line: variable-name
    private _toasterService: ToasterService,
    // tslint:disable-next-line: variable-name
    private _translateCodeToValuePipe: TranslateCodeToValue,
    private _masterService: MasterDataService

  ) { }

  ngOnInit() {
    this.getClientByProviderId();

  }

}
