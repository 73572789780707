import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "capitalize"
})
export class CapitalizePipe implements PipeTransform {
  //   transform(value: string, args?: any): any {
  //     return value
  //       .split(" ")
  //       .map(word => {
  //         return word.length > 2 ? word[0].toLowerCase() + word.substr(1) : word;
  //       })
  //       .join(" ");
  //   }

  transform(input: any, pattern: any): any {
    if (!this.isString(input)) {
      return input;
    }

    const regexp =
      pattern instanceof RegExp ? pattern : new RegExp(pattern, "gi");

    return input.toLowerCase();
    if (input.match(regexp)) {
      return input.toLowerCase();
    }

    return input;
  }

  isString(value: any): boolean {
    return typeof value === "string";
  }
}

// }
