import { LoadingService } from './../../../services/loading.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { EmployeeMasterService } from 'src/app/services/employee-master-service';
import { ToasterService } from 'src/app/services/toaster.service';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  @Output() uploadContent = new EventEmitter<any>();
  fileToUpload: any;
  formData: FormData;
  uploadData: any;
  documentListner: any;

  // wehen user upload the file, this function will execute
  uploadFile(files) {
    try {
      console.log('files', files);
      this._loader.loaderStart();
      this.fileToUpload = files.item(0);
      this.formData = new FormData();
      this.formData.append('file', this.fileToUpload, this.fileToUpload.name);
      console.log("this.formData",this.formData);

      this._httpClientService
        .post('uploadlogo', this.formData, 'apiDevBaseUrladmin')
        .subscribe(
          // success call back
          (data: any) => {
            this.uploadData = data.d;
            // this.uploadOverallData = this.uploadData.d;
            let fileInfo = {
              fileName: this.fileToUpload.name,
              uploadData: this.uploadData
            }
            this.uploadContent.emit(fileInfo);
            this._loader.loaderEnd();
            console.log('get upload', fileInfo);
          }
        ), (err: any) => {
          console.log('error', err);
          this._loader.loaderEnd();
          this._toasterService.error(err.error.m);
        };
    } catch (err) {
      this._loader.loaderEnd();
      this._toasterService.error('Something went wrong.Please try again');
    }
  }


  constructor(
    // tslint:disable-next-line: variable-name
    public _httpClientService: HttpClientService,
    // tslint:disable-next-line: variable-name
    private _loader: LoadingService,
    // tslint:disable-next-line: variable-name
    private _employeeMasterService: EmployeeMasterService,
    // tslint:disable-next-line: variable-name
    private _toasterService: ToasterService
  ) { }

  ngOnInit() {

    // Added this for Clearing the uploaded document



    this.documentListner = this._employeeMasterService.clearDoc.subscribe((value) => {
      this.fileToUpload = null;
    });

  }

  ngOnDestroy() {
    this.documentListner.unsubscribe();
  }

}
