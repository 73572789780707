import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NumberWithoutDecimal',
})
export class NumberWithoutDecimal implements PipeTransform {
  transform(val: string) {
    return new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 0,
    }).format(Number(val));
  }
}
