import { LoadingService } from 'src/app/services/loading.service';
import { CognitoAuth } from './../model/auth';
import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { AuthService } from '../services/auth.service';

// to decode Jwt
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isAuthProgress: boolean = false;
  showModal: boolean = false;
  entities;

  selectedUserEntitie;   // not using now
  selectedUserEntitieType; // not using now
  public currentYear = (new Date()).getFullYear();//Display current year

  authenticate() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('entitie');
    let redirectURL = environment.awsCognitoUrl + environment.cognitoClientId + '&redirect_uri=' + window.location.protocol + '//' + location.host + '/';
    window.location.href = redirectURL;
  }

  verifyAutnticationCode(authCode) {
    try {
      const auth_code = authCode;
      let base64Input = btoa(
        environment.cognitoClientId + ':' + environment.cognitoClientSecret
      );
      let authObject = new CognitoAuth();
      authObject.grant_type = 'authorization_code';
      authObject.client_id = environment.cognitoClientId;
      authObject.redirect_uri = window.location.protocol + '//' + location.host + '/';
      authObject.code = authCode;
      this._authService.login(authObject, base64Input).subscribe(
        (response: any) => {
          this.isAuthProgress = false;

          // opening entities popup
          this.open();

          console.log('response.json().access_token', response.access_token);
          let decodedToken = this._jwtHelperService.decodeToken(response.access_token);
          console.log('decodedToken', decodedToken);
          console.log('response.json().refresh_token', response.refresh_token);
          localStorage.setItem('access_token', response.access_token);
          localStorage.setItem('refreshtoken', response.refresh_token);
          localStorage.setItem('logged_in_user', JSON.stringify(decodedToken));
          localStorage.removeItem('isAuthProgress');

          // getting user entities
          this.getEntities();



        },
        error => {

          console.log('error login', error);
          this.isAuthProgress = false;
          localStorage.removeItem('isAuthProgress');
          this._router.navigate(['/login']);
          if (error && (error.status == 401 || error.status == 400 || error.status == 403)
          ) {
            // Handling Unauthorized access
            console.log('error', error);
          }
        }
      );
    } catch (exception) {
      localStorage.clear();
      this._router.navigate(['/login']);
    }
  }

  getEntities() {
    this._loadingService.loaderStart();
    this._httpClientService.get('entities', 'apiDevBaseUrlsys').subscribe(
      (entitiesres: any) => {
        console.log('entitiesres', entitiesres);
        this._loadingService.loaderEnd();
        this.entities = entitiesres.d;
        this._loggedInUserService.setLoggedInUserEntities(this.entities);
      },
      entitieserror => {
        this._loadingService.loaderEnd();
        console.log('entitieserror', entitieserror);
      }
    );
  }

  open() {
    // open entities modal
    this.showModal = true;
  }

  setSelectedUserEntitie(changedEntitie) {
    console.log('selected entitie by change', JSON.parse(changedEntitie));
    localStorage.setItem('entitie', changedEntitie);
    let entitie = JSON.parse(changedEntitie);
    // this.selectedUserEntitie = entitie.key;
    // this.selectedUserEntitieType = entitie.Type;
    // localStorage.setItem('entitie_key', entitie.Key);
    // localStorage.setItem('entitie_type', entitie.Type);
    // localStorage.setItem('org_id', entitie.OrgId);
    // this._loggedInUserService.setLoggedInUserSelectedEntitie(entitie.Key);
    // this._loggedInUserService.setLoggedInUserSelectedEntitieType(entitie.Tyle);
    // console.log('selected user entitie Key & Type', entitie);
  }

  naviagteToDashboard() {
    console.log('naviagteToDashboard', JSON.parse(localStorage.getItem('entitie')));
    const selectedEntitie = JSON.parse(localStorage.getItem('entitie')).Type;
    // based on the user selected entitie loading the dashboard
    if (selectedEntitie == 'C') {
      // C --> Client
      this._router.navigate(['organization']);
    } else if (selectedEntitie == 'P') {
      // P --> Service Provider
      this._router.navigate(['sp']);
    }
  }





  constructor(
    private _router: Router,
    private _loggedInUserService: LoggedInUserService,
    private _httpClientService: HttpClientService,
    private _authService: AuthService,
    private _jwtHelperService: JwtHelperService,
    private _loadingService: LoadingService
  ) { }

  ngOnInit() {
    let code = localStorage.getItem('authorisationcode') || '';
    console.log('local storage --code', code);
    if (code == '') {
      this.isAuthProgress = false;
    } else {
      this.isAuthProgress = true;
      console.log('isAuthProgress', this.isAuthProgress);
      localStorage.setItem('isAuthProgress', this.isAuthProgress.toString());
      console.log('code', code);
      this.verifyAutnticationCode(code);
    }
  }

}
