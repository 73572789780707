
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  subjectVar = new Subject();

  constructor(private toastr: ToastrService) { }

  success(msg) {
    this.toastr.success(msg);
  }

  error(msg) {
    this.toastr.error(msg, "", {
      disableTimeOut: true,
      closeButton: true
    });
    this.subjectVar.next(false);
  }
  
  
}
